import EndpointApi from './EndpointApi'
import { state } from '/src/store'

class EndpointPrivate extends EndpointApi {
	baseUrl() {
		return `https://${state.backSubdomain}
			.${process.env.VUE_APP_API_DOMAIN}
			/${process.env.VUE_APP_PRIVATE_URL}`
	}
}

export default EndpointPrivate
