<template>
    <img 
        :src="src" 
        class="inline-block rounded-xl object-cover"
    />
</template>

<script>
    export default {
        name: 'Avatar',
        props: {
            src: {
                type: [String],
                default: ''
            }
        }
    }
</script>