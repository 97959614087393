<template>
    <div class="px-6 py-4 flex justify-between items-center">
        <div>
            <router-link
                v-if="route.name !== 'Home'"
                :to="{ name: 'Home' }"
                custom
                v-slot="{ navigate }"
            >
                <button 
                    class="p-1 bg-white rounded-lg"
                    @click="navigate"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/></svg>
                </button>
            </router-link>
        </div>

        <div class="relative">
            <!-- <img
                :src="user.imageProfileURL"
                class="w-12 h-12 object-cover rounded-2xl"
            /> -->

            <Popover class="z-10">
                <template v-slot:button>
                    <Avatar 
                        :src="user.imageProfileURL"
                        class="w-12 h-12"
                    />
                </template>

                <template v-slot:panel>
                    <div class="mt-2 w-40 absolute top-full right-0 bg-white rounded-lg shadow-lg">
                        <div class="  ">
                            <router-link
                                v-if="users.length > 1"
                                :to="{ name: 'UserSelector' }"
                                v-slot="{ navigate }"
                            >
                                <button 
                                    class="px-4 py-2.5 block w-full text-sm"
                                    @click="navigate"
                                >
                                    Cambio de usuario
                                </button>
                            </router-link>

                            <button 
                                class="px-4 py-2.5 block w-full text-sm text-red-500"
                                :class="{ 'border-t': users.length > 1 }"
                                @click="logout"
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </template>
            </Popover>
        </div>
    </div>
</template>

<script>
    import { computed } from "vue";
    import { useRoute, useRouter } from 'vue-router';

    import Avatar from '@/components/avatar';
    import Popover from '@/components/popover';

    import { alert } from '@/components/alerts';

    import Auth from '@/api/endpoints/Auth.js';

    import { state, actions } from "@/store";


    export default {
        name: "Header",
        components: {
            Avatar,
            Popover
        },
        setup(props) {
            const route = useRoute();
            const router = useRouter();

            const users = computed(() => state.users);
            const user = computed(() => state.user);

            async function logout() {
                alert({
                    title: 'Loginout',
                    text: `¿Estás seguro de que quieres salir?`,
                    confirmLabel: 'Logout',
                    cancelLabel: 'Cancelar',
                    confirm: async ({ close }) => {
                        await Auth.logout();

                        actions.setToken(null);
                        await router.push({ name: "Login" });

                        actions.resetAuth();

                        close();
                    }
                });
            }

            return {
                route,
                users,
                user,
                logout
            };
        },
    };
</script>