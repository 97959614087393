<template>
    <Popover 
        class="relative"
        v-slot="{ open }" 
    >
        <PopoverButton
            :class="open ? '' : 'text-opacity-90'"
            class="block"
        >
            <slot 
                name="button"
                :open="open"
            />
        </PopoverButton>

        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
        >
            <PopoverPanel
                class=""
            >
                <slot 
                    name="panel" 
                    :open="open"
                />
            </PopoverPanel>
        </transition>
    </Popover>
</template>

<script>
    import { 
        Popover, 
        PopoverButton, 
        PopoverPanel 
    } from "@headlessui/vue";

    export default {
        components: { 
            Popover, 
            PopoverButton, 
            PopoverPanel
        },
        setup() {
                  
        },
    };
</script>
